<template>
	<v-app style="background-color: #ffffff">
		<v-main>
			<router-view />
		</v-main>

		<Footer />
	</v-app>
</template>

<script>
import Footer from "./components/Footer";

export default {
	name: "App",
	components: { Footer },
	data: () => ({}),
};
</script>